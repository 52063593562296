import React from "react";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Form, Input, Button, message } from "antd";

const { Title } = Typography;
const Index = () => {
  const history = useHistory();
  const authProps = useSelector((state) => state.auth);
  const { ForSuccess, ForError, ShowForgotLoader, ForMessage } = authProps;
  const dispatch = useDispatch();
  const onFinish = (values) => {
    values.email = values.email.toLowerCase();
    dispatch(
      DataRequestAction(
        "POST",
        "forgetPassword",
        "forgetPassword",
        values,
        "forgot",
        "",
        ""
      )
    );
  };

  if (ForSuccess) {
    message.success("Email send in your inbox");
    history.push("/login");
    dispatch({
      type: "update_auth",
    });
  }

  if (ForError) {
    message.error(ForMessage);
    dispatch({
      type: "update_auth",
    });
  }

  return (
    <div className="gx-p-3">
      <Form
        layout="vertical"
        className="gx-py-3 gx-d-flex gx-flex-column gx-mx-auto"
        style={{ width: "320px" }}
        onFinish={onFinish}
      >
        <Title
          level={2}
          className="gx-d-flex gx-justify-content-center gx-mb-3"
        >
          Forget Password
        </Title>
        <span
          className="gx-mx-auto gx-text-center gx-d-flex gx-justify-content-center gx-mb-5 "
          style={{ width: "88%", color: "#022C43" }}
        >
          Please enter your registered email below to receive password reset
          instructions.
        </span>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
            {
              type: "email",
              message: "Enter Valid Email",
            },
          ]}
        >
          <Input className="gx-text-lowercase" />
        </Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          className="gx-w-100 gx-mt-4 gx-py-0"
          loading={ShowForgotLoader}
          disabled={ShowForgotLoader}
        >
          Send
        </Button>
      </Form>
    </div>
  );
};

export default Index;
