import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Row, Col, Button, Spin, message } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
const ResetPasswordModal = () => {
  const dispatch = useDispatch();
  const crudModal = useSelector((state) => state.CurdR);
  const { Reset_Modal } = crudModal;
  const resetProps = useSelector((state) => state.auth);
  const { PasswordChangeSuccess, resetSpinner, PasswordChangeFailed } =
    resetProps;

  if (PasswordChangeSuccess) {
    dispatch({
      type: "update_auth",
    });
    message.success("Password Change Successfully");
    dispatch({
      type: "Hide_Modal",
      payload: null,
      ModalName: "Reset_Modal",
    });
  }
  if (PasswordChangeFailed) {
    dispatch({
      type: "update_auth",
    });
    message.error("Old password not match");
  }

  const changePassword = (values) => {
    dispatch(
      DataRequestAction(
        "POST",
        "resetPassword",
        "RestPassword",
        values,
        "ResetSpinner",
        "Reset_Modal",
        ""
      )
    );
  };
  const toggleModal = () => {
    dispatch({
      type: "Show_Modal",
      payload: null,
      ModalName: "Reset_Modal",
    });
  };
  return (
    <>
      <small className="gx-text-primary gx-link" onClick={() => toggleModal()}>
        Change password
      </small>
      <SimpleModal
        width={500}
        title="Change Password"
        modalFor="Reset_Modal"
        visible={Reset_Modal}
      >
        <Form layout="vertical" name="resetPasswprd" onFinish={changePassword}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="oldPassword"
                label={"Old Password"}
                rules={[
                  {
                    required: true,
                    message: "Old Password is required",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row className="gx-d-flex gx-flex-row">
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                name="confirmPassword"
                label={"Confirm Password"}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirm password is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Confirm Password is not match");
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">
                <Form.Item className="gx-mb-0">
                  <Button
                    type="default"
                    className="gx-pt-0 gx-mb-0 gx-mr-2"
                    htmlType="button"
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item className="gx-mb-0">
                  {resetSpinner && (
                    <Spin>
                      <Button type="primary" className="gx-pt-0 gx-mb-0">
                        Update
                      </Button>
                    </Spin>
                  )}

                  {!resetSpinner && (
                    <Button
                      type="primary"
                      className="gx-pt-0 gx-mb-0"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  )}
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </SimpleModal>
    </>
  );
};

export default ResetPasswordModal;
