import React, { useEffect } from 'react'
import { Table, message } from 'antd';
import { DataGetAction } from '../../redux/actions/CommonHttp'
import { useDispatch, useSelector } from 'react-redux'



const Index = ({ 
    columns,
    scroll,
    pagination,
    apiName,
    recordName,
    callOnCondition,
    customQuery = {},
    tempData
}) => {

    const dispatch = useDispatch()
    const CurdProps = useSelector(state => state.CurdR)

    const fetchList = () => {
        dispatch(DataGetAction(apiName, 'FetchRecord', { query: 'all', ...customQuery }, 'StartSpinner','',recordName));
    }
    useEffect(fetchList, [callOnCondition])


    const {  success, error } = CurdProps;
    if (success) {
        message.success(CurdProps.message)
        dispatch({
            type: 'ResetAll_State'
        })
    }

    if (error) {
        message.error(CurdProps.message)
        dispatch({
            type: 'ResetAll_State'
        })
    }


    return <Table
        className="BasicTable gx-table-responsive "
        columns={columns}
        dataSource={ tempData ? tempData : CurdProps[recordName]}
        scroll={scroll}
        pagination={pagination}
    />
}

export default Index
