import { message, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { DataGetAction } from "../../redux/actions/http";
import { CaretLeftOutlined } from "@ant-design/icons";
const StaticPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const authProps = useSelector((state) => state.auth);
  const { staticPageData, staticPageSpin, staticPageError } = authProps;
  if (staticPageError) {
    dispatch({ type: "update_auth" });
    message.error("Some Error Occur try later");
    history.push("/login");
  }

  const check = location && location.state ? true : false;
  useEffect(() => {
    if (location && location.state) {
      dispatch(
        DataGetAction(
          "getStaticPage",
          {
            type: location && location.state && location.state.type,
            portal: location && location.state && location.state.portal,
          },
          "StaticPagesSpin"
        )
      );
    }
  }, [check]);
  return (
    <div className="gx-p-5">
      {staticPageSpin && <Spin />}
      {!staticPageSpin && (
        <div>
          <div
            className="gx-font-sans-bold gx-mb-3"
            style={{ fontSize: "24px" }}
          >
            <CaretLeftOutlined
              className="gx-mr-2 gx-pointer"
              onClick={() => history.goBack()}
            />

            {staticPageData && staticPageData.title}
          </div>
          <div>
            <p
              style={{ fontSize: "18px" }}
              dangerouslySetInnerHTML={{
                __html: staticPageData && staticPageData.description,
              }}
            />{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default StaticPage;
