import React, { useEffect, useState } from "react";
import Widget from "components/Widget";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Checkbox } from "antd";
import ResetPasswordModal from "./components/ResetPasswordModal";
import EditProfile from "./components/EditProfile";
import Layout from "components/LayoutForPortal";
import { DataGetAction } from "redux/actions/CommonHttp";
import dummy from "assets/images/dummyImage.jpeg";
const CheckboxGroup = Checkbox.Group;
const Index = () => {
  const dispatch = useDispatch();
  const styles = {
    img: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
  };
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);

  const settingsProps = useSelector((state) => state.auth);
  const { settingsData } = settingsProps;

  const fetchSettings = () => {
    dispatch(
      DataGetAction("getProfile", "getProfile", "", "gettingProfile", "", "")
    );
    if (settingsData && settingsData.profileImageUrl) {
      photoList.imageUrl = settingsData.profileImageUrl;
    }
  };

  useEffect(fetchSettings, [settingsData.profileImageUrl]);

  return (
    <Layout>
      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Widget>
            <div className="gx-d-flex gx-align-items-flex-start gx-justify-content-between">
              <div className="gx-d-flex">
                {settingsData && settingsData.profileImageUrl ? (
                  <img
                    src={settingsData && settingsData.profileImageUrl}
                    alt=""
                    style={styles.img}
                  />
                ) : (
                  <img src={dummy} alt="" style={styles.img} />
                )}

                <div className="details gx-ml-3 gx-d-flex gx-flex-column">
                  <h4
                    className="gx-font-sans-bold"
                    style={{ color: "#595959" }}
                  >
                    <span>{settingsData && settingsData.fullName}</span>
                  </h4>
                  <ResetPasswordModal />
                </div>
              </div>

              <div>
                <EditProfile
                  photoList={photoList}
                  setPhotoState={setPhotoState}
                />
              </div>
            </div>
            <div className="gx-d-flex gx-justify-content-between gx-my-4">
              <div className="gx-d-flex gx-align-items-center">
                <span
                  className="icon icon-email gx-text-primary"
                  style={{ fontSize: "20px" }}
                ></span>
                <div className="details gx-ml-3 gx-d-flex gx-flex-column">
                  <span style={{ color: "#D2D2D2" }}>Email</span>
                  <span style={{ color: "#595959" }}>
                    {(settingsData && settingsData.email) || "----"}
                  </span>
                </div>
              </div>
            </div>
            <div className="gx-d-flex gx-justify-content-between gx-my-4">
              <div className="gx-d-flex gx-align-items-center">
                <span
                  className="icon icon-phone gx-text-primary"
                  style={{ fontSize: "20px" }}
                ></span>
                <div className="details gx-ml-3 gx-d-flex gx-flex-column">
                  <span style={{ color: "#D2D2D2" }}>Phone Number</span>
                  <span style={{ color: "#595959" }}>
                    +{(settingsData && settingsData.phoneNumber) || "-----"}
                  </span>
                </div>
              </div>
            </div>
            <div className="details gx-ml-4  gx-d-flex gx-flex-column">
              <span className="gx-pl-3" style={{ color: "#D2D2D2" }}>
                Brief Bio
              </span>
              <span className="gx-pl-3" style={{ color: "#595959" }}>
                {(settingsData && settingsData.description) || "-----"}
              </span>
            </div>
            <div className="details gx-ml-4 gx-mt-3 gx-d-flex gx-flex-column">
              <span className="gx-pl-3" style={{ color: "#D2D2D2" }}>
                Experience with online moderating
              </span>
              <span className="gx-pl-3" style={{ color: "#595959" }}>
                {settingsData && settingsData.moderatorExperience && (
                  <CheckboxGroup
                    defaultValue={
                      settingsData && settingsData.moderatorExperience
                    }
                    disabled
                    options={settingsData && settingsData.moderatorExperience}
                  />
                )}
              </span>
            </div>
          </Widget>
        </Col>
      </Row>
    </Layout>
  );
};

export default Index;
