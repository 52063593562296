import React from "react";
import FormModal from "components/Modal/FormModal";
import CommunityForm from "./CommunityForm";
import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
const AddCommunity = ({ FetchCommunityList }) => {
  const CommunityProps = useSelector((state) => state.CurdR);
  const { Add_Community_Modal, Loader } = CommunityProps;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  return (
    <div>
      <Button
        className="gx-py-0 gx-m-0"
        type="primary"
        style={{ width: "100px" }}
        onClick={() => {
          dispatch({
            type: "Show_Modal",
            payload: null,
            ModalName: "Add_Community_Modal",
          });
        }}
      >
        Add New
      </Button>
      <FormModal
        modalTitle="Moderator"
        basicButtonTitle="Reset Password"
        submitButtonText="Add"
        cancelButtonText="Cancel"
        apiMethod="POST"
        addApiName="addCommunityDiscussion"
        addApiRequest="AddRecord"
        apiLoader="StartSpinner"
        ModalName="Add_Community_Modal"
        confirmLoading={Loader}
        ModalType="Hide_Modal"
        visible={Add_Community_Modal ? Add_Community_Modal : false}
        buttonStyleClass="gx-buttonStyleClass"
        isShowButton={false}
        width={450}
        recordName="communityList"
        form={form}
      >
        <CommunityForm FetchCommunityList={FetchCommunityList} />
      </FormModal>
    </div>
  );
};

export default AddCommunity;
