import React, { useEffect } from "react";
import { Form, Input, Button, Spin, Checkbox, Row, Col } from "antd";
import Widget from "../../Widget";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useHistory, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "react-phone-input-2/lib/style.css";
const SignUp = (props) => {
  const params = useParams();
  const { token } = params;
  var decoded = jwt_decode(token);
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const history = useHistory();
  const { savingDetail, userRegisterSuccessfully } = authProps;
  const onFinish = (values) => {
    values._id = decoded._id;
    dispatch(
      DataRequestAction(
        "POST",
        "registerModerator",
        "registerSpecialist",
        values,
        "savingUserDetail",
        "",
        ""
      )
    );
  };

  if (userRegisterSuccessfully) {
    history.push("/communityDiscussion");
    dispatch({ type: "update_auth" });
  }

  const styles = {
    inputIcon: {
      display: "flex",
      marginRight: "10px",
    },
  };

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
  }, [token]);

  const initialValue = {
    fullName: decoded.fullName,
    email: decoded.email,
    _id: decoded._id,
  };
  const redirectPageTermCondition = (type, portal) => {
    history.push({
      pathname: "/termAndConditions",
      state: { type: type, portal: portal },
    });
  };
  const redirectPagePrivacyPolicy = (type, portal) => {
    history.push({
      pathname: "/privacyPolicy",
      state: { type: type, portal: portal },
    });
  };

  return (
    <Widget styleName="gx-paddingAuth">
      <div className="SignIn gx-px-5 gx-pb-5">
        <div className="welcomeMessage gx-text-center gx-mb-4">
          <h2 className="gx-font-weight-light gx-mb-0 gx-font-sans-regular gx-blue-font gx-font-35">
            Create Your Account
          </h2>
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={initialValue}
          onFinish={onFinish}
        >
          <Form.Item
            name="fullName"
            className="gx-p-0 gx-m-0 gx-mb-3"
            rules={[
              { required: true, message: "Please input your full name!" },
            ]}
          >
            <Input
              placeholder="Full Name"
              prefix={
                <span
                  style={styles.inputIcon}
                  className="icon icon-avatar"
                ></span>
              }
            />
          </Form.Item>
          <Form.Item
            name="email"
            className="gx-p-0 gx-m-0 gx-mb-3"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              type="email"
              disabled
              placeholder="Email"
              prefix={
                <span
                  style={styles.inputIcon}
                  className="icon icon-email"
                ></span>
              }
            />
          </Form.Item>

          <Form.Item
            name="password"
            className="gx-p-0 gx-m-0 gx-mb-3"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password
              placeholder="Password"
              prefix={
                <span
                  style={styles.inputIcon}
                  className="icon icon-forgot-password"
                ></span>
              }
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            className="gx-p-0 gx-m-0 gx-mb-3"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Confirm password is required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Password is not match");
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              prefix={
                <span
                  style={styles.inputIcon}
                  className="icon icon-forgot-password"
                ></span>
              }
            />
          </Form.Item>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-pl-5 gx-d-flex gx-align-items-center"
              style={{ gap: "6.2px" }}
            >
              <Form.Item
                name="remember"
                className="gx-mb-0"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Acceptance of Terms and Privacy Policy is mandatory"
                            )
                          ),
                  },
                ]}
              >
                <Checkbox>
                  By sign up, I accept{" "}
                  <span
                    onClick={() =>
                      redirectPageTermCondition(
                        "termAndConditions",
                        "moderator"
                      )
                    }
                    style={{ color: "#3FD4A2" }}
                    className="gx-pointer"
                  >
                    {" "}
                    Terms and Conditions
                  </span>{" "}
                  and{" "}
                  <span
                    onClick={() =>
                      redirectPagePrivacyPolicy("privacyPolicy", "moderator")
                    }
                    style={{ color: "#3FD4A2" }}
                    className="gx-pointer"
                  >
                    Privacy Policy
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="gx-p-0 gx-m-0">
            {!savingDetail && (
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center gx-font-sans-regular"
                style={{ lineHeight: "0px" }}
              >
                Sign up
              </Button>
            )}
            {savingDetail && (
              <Spin>
                <Button
                  type="primary"
                  htmlType="button"
                  className="login-form-button gx-mb-0 gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center gx-font-sans-regular"
                  style={{ lineHeight: "0px" }}
                >
                  Sign up
                </Button>
              </Spin>
            )}
          </Form.Item>
        </Form>
      </div>
    </Widget>
  );
};

export default SignUp;
