const MenuList = {
  moderator: [
    {
      title: "Dashboard",
      url: "/dashboard",
      icon: "dasbhoard",
      top: "4px",
      permissionKey: "dashboard",
      key: "superAdmin",
    },
    {
      title: "Community Discussion",
      url: "/communityDiscussion",
      icon: "family",
      top: "4px",
      permissionKey: "communityDiscussion",
      key: "communityDiscussion",
    },
    {
      title: "Setting",
      url: "/setting",
      icon: "setting",
      top: "4px",
      permissionKey: "setting",
      key: "setting",
    },
  ],
};

export default MenuList;
