import React from "react";
import { useDispatch } from "react-redux";
const EditButton = ({ record, modalName, className, buttonName }) => {
  const dispatch = useDispatch();
  return (
    <span
      onClick={() =>
        dispatch({
          type: "Show_Modal",
          payload: record,
          ModalName: modalName,
        })
      }
      className={`gx-pointer ${className}`}
    >
      {buttonName}
    </span>
  );
};

export default EditButton;
