import {
  addItemInRecord,
  recordAfterUpdate,
  recordAfterDeleted,
} from "./CommonFn";

const initialState = {
  Records: [],
  success: false,
  error: false,
  message: "",
  Loader: false,
  initialValues: null,
  savingSpinner: false,
  fetchSuccess: false,
  DeleteError: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "Show_Modal": {
      return {
        ...state,
        [action.ModalName]: true,
        initialValues: action.payload,
      };
    }
    case "Hide_Modal": {
      return {
        ...state,
        [action.ModalName]: false,
        initialValues: action.ModalName ? null : state.initialValues,
      };
    }
    case "StartSpinner_START": {
      return {
        ...state,
        Loader: true,
        savingSpinner: false,
      };
    }
    case "StartSavingSpinner_START": {
      return {
        ...state,
        Loader: true,
        savingSpinner: true,
      };
    }

    case "FetchSingleRecord_SUCCESS":
      return {
        ...state,
        SingleRecord: action.payload.Record,
        Loader: false,
      };
    case "DeleteComment_SUCCESS": {
      return {
        ...state,
        deleteSuccess: true,
      };
    }
    case "FetchSingleRecord_FAILURE":
      return {
        ...state,
        error: true,
        SingleRecord: null,
        message: "Record Fetch Error",
        Loader: false,
      };

    case "FetchRecord_SUCCESS":
      return {
        ...state,
        [action.recordName]: action.payload.Record && action.payload.Record,
        Loader: false,
        fetchSuccess: true,
      };

    case "FetchRecord_FAILURE":
      return {
        ...state,
        error: true,
        message: "Record Fetch Error",
        Loader: false,
        fetchSuccess: false,
      };

    case "AddRecord_SUCCESS":
      let Record = addItemInRecord(
        state[action.recordName],
        action.payload.Record
      );

      return {
        ...state,
        [action.recordName]: [...Record],
        Loader: false,
        success: true,
        message: action.payload.message
          ? action.payload.message
          : "Record Added Successfully",
        savingSpinner: false,
      };

    case "AddRecord_FAILURE":
      return {
        ...state,
        Loader: false,
        error: true,
        message: action.payload.message
          ? action.payload.message
          : "Record Added Fail",
        savingSpinner: false,
      };
    case "AlreadyExist_FAILURE":
      return {
        ...state,
        Loader: false,
        error: true,
        message: action.payload,
        savingSpinner: false,
      };

    case "EditRecord_SUCCESS":
      let EditRecord = recordAfterUpdate(
        state[action.recordName],
        action.payload.Record
      );
      return {
        ...state,
        [action.recordName]: [...EditRecord],
        Loader: false,
        success: true,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Successfully",
        savingSpinner: false,
      };

    case "EditRecord_FAILURE":
      return {
        ...state,
        Loader: false,
        error: true,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Fail",
        savingSpinner: false,
      };

    case "DeleteRecord_SUCCESS":
      let deleteRecord = recordAfterDeleted(
        state[action.recordName],
        action.payload.Record
      );

      return {
        ...state,
        [action.recordName]: [...deleteRecord],
        Loader: false,
        success: true,
        message: action.payload.message
          ? action.payload.message
          : "Record Delete Successfully",
        savingSpinner: false,
      };

    case "DeleteRecord_ERROR":
      return {
        ...state,
        Loader: false,
        DeleteError: true,
        message: action.payload,
        savingSpinner: false,
      };

    case "DeleteRecord_FAILURE":
      return {
        ...state,
        Loader: false,
        DeleteError: true,
        message: action.payload.message
          ? action.payload.message
          : "Record Edit Fail",
        savingSpinner: false,
      };

    case "ResetAll_State":
      return {
        ...state,
        success: false,
        deleteSuccess: false,
        error: false,
        DeleteError: false,
        message: "",
        Loader: false,
        savingSpinner: false,
        fetchSuccess: false,
      };

    default:
      return state;
  }
};
