import React from "react";
import { Button, Modal, Form, message } from "antd";
import { useDispatch } from "react-redux";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import { printConsole } from "../../utils/commonUseFunction";

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  confirmLoading,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  children,
  width,
  form,
  className,
  showFooter,
}) => {
  if (!visible) {
    form.resetFields();
  }

  return (
    <Modal
      visible={visible}
      title={modalTitle}
      width={width}
      form={form}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      okText={submitButtonText}
      cancelText={cancelButtonText}
      className={className}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      okButtonProps={{ disabled: showFooter }}
      confirmLoading={confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {});
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        {children}
      </Form>
    </Modal>
  );
};

const CollectionsPage = ({
  children,
  modalTitle,
  basicButtonTitle,
  submitButtonText,
  cancelButtonText,
  confirmLoading,
  apiMethod,
  addApiName,
  addApiRequest,
  visible,
  ModalType,
  width,
  className,
  apiLoader,
  buttonStyleClass,
  isShowButton = true,
  extraFieldName,
  secondExtraFieldName,
  secondExtraFieldValue,
  extraFieldValue,
  showFooter,
  ModalName,
  recordName,
  form,
}) => {
  const dispatch = useDispatch();
  const onCreate = (values) => {
    if (extraFieldName) {
      values[extraFieldName] = extraFieldValue;
    }
    if (secondExtraFieldName) {
      values[secondExtraFieldName] = secondExtraFieldValue;
    }
    printConsole("successs", values);

    if (
      ModalName === "" ||
      (ModalName === "AddCategory_Modal" && extraFieldValue === "")
    ) {
      return message.info("Upload Image");
    } else {
      dispatch(
        DataRequestAction(
          apiMethod,
          addApiName,
          addApiRequest,
          values,
          apiLoader,
          ModalName,
          recordName
        )
      );
    }
  };

  return (
    <div>
      {isShowButton && (
        <Button
          type="primary"
          className={`gx-pt-0 ${buttonStyleClass}`}
          onClick={() => {
            dispatch({
              type: ModalType,
              payload: null,
            });
          }}
        >
          {basicButtonTitle}
        </Button>
      )}
      <CollectionCreateForm
        modalTitle={modalTitle}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        apiMethod={apiMethod}
        ModalType={ModalType}
        confirmLoading={confirmLoading}
        apiLoaderName={apiLoader}
        visible={visible}
        width={width}
        className={className}
        showFooter={showFooter}
        onCreate={onCreate}
        form={form}
        onCancel={() => {
          dispatch({
            type: ModalType,
            payload: null,
            ModalName: ModalName,
            clear: true,
          });
        }}
      >
        {children}
      </CollectionCreateForm>
    </div>
  );
};

export default CollectionsPage;
