import React from "react";
import CommunityForm from "./CommunityForm";
import FormModal from "components/Modal/EditFormModal";
import { useSelector } from "react-redux";
import moment from "moment";
const EditCommunity = ({ FetchCommunityList }) => {
  const CommunityProps = useSelector((state) => state.CurdR);
  const { Edit_Community_Model, Loader, initialValues } = CommunityProps;
  if (initialValues && initialValues.publishDate) {
    initialValues.publishDate = moment(initialValues.publishDate);
  }

  return (
    <FormModal
      modalTitle="Edit"
      basicButtonTitle="Reset Password"
      submitButtonText="Update"
      cancelButtonText="Cancel"
      editApiRequest="EditRecord"
      apiLoader="StartSpinner"
      editApiMethod="PUT"
      editApiName="editCommunityDiscussion"
      ModalName="Edit_Community_Model"
      confirmLoading={Loader}
      ModalType="Hide_Modal"
      visible={Edit_Community_Model ? Edit_Community_Model : false}
      buttonStyleClass="gx-buttonStyleClass"
      isShowButton={false}
      initialValues={initialValues}
      width={450}
      recordName="communityList"
    >
      <CommunityForm FetchCommunityList={FetchCommunityList} />
    </FormModal>
  );
};

export default EditCommunity;
