import React from "react";
import { Row, Col, Form, Select, Input, DatePicker } from "antd";
import moment from "moment";
const { Option } = Select;
const CommunityForm = ({ FetchCommunityList }) => {
  return (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="community"
          label="Community"
          rules={[
            {
              required: true,
              message: "Community is required",
            },
          ]}
        >
          <Select>
            {FetchCommunityList &&
              FetchCommunityList.map((item) => (
                <Option value={item._id}>{item.communityName}</Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="title"
          label="Topic of the day"
          rules={[
            {
              required: true,
              message: "topic of the day is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="tags"
          label="Tags"
          rules={[
            {
              required: true,
              message: "Tags is required",
            },
          ]}
        >
          <Select open={false} mode="tags"></Select>
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          name="publishDate"
          label="Calender"
          rules={[
            {
              required: true,
              message: "Date is required",
            },
          ]}
        >
          <DatePicker
            disabledDate={(current) =>
              current.isBefore(moment().subtract(1, "day"))
            }
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CommunityForm;
