import React from "react";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Form, Input, Button, message } from "antd";
import { useParams } from "react-router-dom";
const { Title } = Typography;
const Index = () => {
  const history = useHistory();
  const params = useParams();
  const authProps = useSelector((state) => state.auth);
  const { setError, setSuccess, setSpinner, setMessage } = authProps;
  const dispatch = useDispatch();
  const onFinish = (values) => {
    if (params && params.query !== "") {
      dispatch(
        DataRequestAction(
          "POST",
          "setNewPassword",
          "setNewPassword",
          { ...values, forgetPasswordAuthToken: params.query },
          "setSpinner",
          "",
          ""
        )
      );
    }
  };

  if (setSuccess) {
    message.success("Your password updated successfully");
    history.push("/login");
    dispatch({ type: "update_auth" });
  }

  if (setError) {
    message.error(setMessage);
    dispatch({
      type: "update_auth",
    });
  }

  return (
    <div className="gx-p-3">
      <Form
        layout="vertical"
        className="gx-py-3 gx-d-flex gx-flex-column gx-mx-auto"
        style={{ width: "320px" }}
        onFinish={onFinish}
      >
        <Title
          level={2}
          className="gx-d-flex gx-justify-content-center gx-mb-3"
        >
          Set New Password
        </Title>
        <span
          className="gx-mx-auto gx-text-center gx-d-flex gx-justify-content-center gx-mb-5"
          style={{ width: "88%", color: "#022C43" }}
        >
          Please Enter Your New Password
        </span>

        <span className="gx-mb-2 gx-ml-1 gx-font-sans-bold">Password</span>
        <Form.Item
          name="password"
          className="gx-p-0 gx-m-0 gx-mb-4"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>
        <span className="gx-mb-2 gx-ml-1 gx-font-sans-bold">
          Confirm Password
        </span>
        <Form.Item
          name="confirmPassword"
          className="gx-p-0 gx-m-0 gx-mb-4"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Confirm password is required",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Password is not match"));
              },
            }),
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>

        <Button
          htmlType="submit"
          type="primary"
          className="gx-w-100 gx-mt-4 gx-py-0"
          loading={setSpinner}
          disabled={setSpinner}
        >
          Update
        </Button>
      </Form>
    </div>
  );
};

export default Index;
