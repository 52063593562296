import React from "react";
import { DataRequestAction } from "redux/actions/CommonHttp";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import AvatarUploader from "components/AvatarUploader";
import { Form, Input, Row, Col, Button, Spin, message, Checkbox } from "antd";

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
const EditProfile = ({ setPhotoState, photoList }) => {
  const settingsProps = useSelector((state) => state.auth);
  const { settingsData, updatingProfile, profileEditeSuccess, textMessage } =
    settingsProps;
  console.log("=====settingsData====", settingsData);
  const crudProps = useSelector((state) => state.CurdR);
  const { profile_modal } = crudProps;
  const dispatch = useDispatch();
  const plainOptions = [
    "Ran facebook group",
    "Ran instagram",
    "In person group",
  ];
  const toggleEditProfile = () => {
    dispatch({
      type: "Show_Modal",
      ModalName: "profile_modal",
      payload: null,
    });
  };
  const closeModal = () => {
    dispatch({
      type: "Hide_Modal",
      ModalName: "profile_modal",
      payload: null,
    });
  };
  if (profileEditeSuccess) {
    message.success(textMessage);
    dispatch({
      type: "Hide_Modal",
      ModalName: "profile_modal",
      payload: null,
    });
    dispatch({
      type: "reset_profilesettings",
    });
  }

  const updateProfle = (values) => {
    values.profileImageUrl = photoList.imageUrl;
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProfile",
        "updateProfile",
        values,
        "updatingProle",
        "",
        ""
      )
    );
  };

  return (
    <div>
      <span
        style={{ color: "grey" }}
        className="icon icon-edit gx-link"
        onClick={() => toggleEditProfile()}
      ></span>
      <SimpleModal
        modalFor="profile_modal"
        width={500}
        title={"Edit Profile"}
        visible={profile_modal}
      >
        <span></span>
        <Form
          layout="vertical"
          name="editProfile"
          onFinish={updateProfle}
          initialValues={settingsData && settingsData}
        >
          <Row className="gx-d-flex gx-flex-row gx-align-items-center">
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
              <Form.Item
                name="fullName"
                label={"Full Name"}
                rules={[
                  {
                    required: true,
                    message: "full name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "email is required",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Phone number is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pr-0">
              <Form.Item name="photo">
                <AvatarUploader
                  setPhotoState={setPhotoState}
                  photoList={photoList}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0 ">
              <Form.Item name="description" label="Brief Bio">
                <TextArea />
              </Form.Item>
              <span>Experience with online moderating</span>
              <Form.Item
                name="moderatorExperience"
                className="gx-mb-1"
                valuePropName="checked"
                initialValue={settingsData && settingsData.moderatorExperience}
              >
                <CheckboxGroup
                  defaultValue={
                    settingsData && settingsData.moderatorExperience
                  }
                  options={plainOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-px-0 gx-mt-3"
            >
              <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">
                <Form.Item className="gx-mb-0">
                  <Button
                    type="default"
                    className="gx-pt-0 gx-mb-0 gx-mr-2"
                    htmlType="button"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item className="gx-mb-0">
                  {updatingProfile && (
                    <Spin>
                      <Button
                        type="primary"
                        className="gx-pt-0 gx-mb-0"
                        htmlType="button"
                        disbaled
                      >
                        Update
                      </Button>
                    </Spin>
                  )}

                  {!updatingProfile && (
                    <Button
                      type="primary"
                      className="gx-pt-0 gx-mb-0"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  )}
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </SimpleModal>
    </div>
  );
};

export default EditProfile;
