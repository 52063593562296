import React, { useEffect } from "react";
import Layout from "components/LayoutForPortal";
import Widget from "components/Widget";
import CommunityList from "components/Table/List";
import ActionButton from "components/Table/Actions";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import SelectFilter from "components/Filters/SelectFilter";
import { useDispatch, useSelector } from "react-redux";
import EditCommunity from "./components/EditCommunity";
import AddCommunity from "./components/AddCommunity";
import SearchBox from "components/Filters/SearchBox";
import { DataGetAction } from "redux/actions/CommonHttp";
import { renderStatus } from "utils/commonUseFunction";
import ShowModal from "components/Modal/ShowModal";
import CommentModal from "./components/CommentModal";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const Index = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const CommunityProps = useSelector((state) => state.CurdR);
  const { Edit_Community_Model, FetchCommunityList } = CommunityProps;

  const FetchCommunity = () => {
    dispatch(DataGetAction("getMyCommunities", "FetchRecord", "", "", "", "FetchCommunityList"));
  };

  useEffect(FetchCommunity, []);

  const openTheCommentDetailPage = (data) => {
    
    history.push({
      pathname: "/communityDiscussion/commentDetail",
      state: {
        communityId: data.community,
        discussionId: data._id,
        topicOfDay: data.title,
        createdBy: data.createdBy,
        totalComments: 0,
        totalEmpathies: 0,
        RecipeCount: 0,
        ExerciseCount: 0,
      },
    });
  };

  const Columns = [
    {
      title: "ID",
      render: (record) => <span>{record.CDId}</span>,
      key: "id",
      width: "80px",
    },
    {
      title: "Creator",
      render: (record) => <span>{record.createdBy}</span>,
      key: "creator",
      width: "150px",
    },
    {
      title: "Community",
      render: (record) => <span>{record.communityName}</span>,
      key: "community",
      width: "150px",
    },
    {
      title: "Topic of a day",
      render: (record) => <span>{record.title}</span>,
      key: "TopicOfDay",
    },
    {
      title: "Comments",
      render: (record) => (
        <Button type="link" onClick={() => openTheCommentDetailPage(record)}>
          <span role="img" aria-label="flower">
            💬
          </span>
          <span className="gx-ml-2">{record.totalComments}</span>
        </Button>
      ),
      key: "comments",
    },
    {
      title: "Status",
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => <ActionButton>{renderAction(record)}</ActionButton>,
      key: "actionButton",
    },
  ];

  const editRecord = (record) => {
    dispatch({
      type: "Show_Modal",
      payload: record,
      ModalName: "Edit_Community_Model",
    });
  };

  const renderAction = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <span onClick={() => editRecord(record)} style={{ cursor: "pointer" }}>
          Edit
        </span>
        <DeleteConfirm
          type="deleteCommunityDiscussion"
          method="DELETE"
          selectedItem={record}
          apiRequest="DeleteRecord"
          recordName="communityList"
          buttonName="Yes"
          oKText="Delete"
          title="Are you sure you want to delete this Community Discussion?"
          content=""
          Icon={
            <span
              className="gx-font-gilory-regular gx-link"
              style={{
                fontSize: "14px",
                color: "#545454",
                verticalAlign: "top",
              }}
            >
              Delete
            </span>
          }
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        title={<h4 className="gx-mb-0 gx-text-capitalize gx-font-gilory-bold">Community Discussion</h4>}
        extra={
          <div className="gx-d-flex gx-align-items-center">
            <div style={{ height: "100%", width: "200px", marginRight: "10px" }}>
              <SelectFilter
                isShowingAll={true}
                apiName="getMyCommunityDiscussion"
                searchKey="status"
                recordName="communityList"
                options={[
                  { status: "publish", label: "Publish" },
                  { status: "pending", label: "Pending" },
                ]}
                placeholder="Search by status"
                ItemValue="status"
                label="label"
              />
            </div>
            <div className="gx-mr-2">
              <SearchBox
                apiName="getMyCommunityDiscussion"
                requestType="FetchRecord"
                placeholder="Creator/Community/Topic"
                recordName="communityList"
                customQuery={{ query: "search", key: "searchTopicOfDay" }}
              />
            </div>
            <AddCommunity FetchCommunityList={FetchCommunityList} />
          </div>
        }
      >
        <CommunityList apiName="getMyCommunityDiscussion" recordName="communityList" columns={Columns} />
      </Widget>
      {Edit_Community_Model && <EditCommunity FetchCommunityList={FetchCommunityList} />}
      <CommentModal />
    </Layout>
  );
};

export default Index;
